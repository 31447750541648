import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import Collection from '../../../components/collection'


const metaTags = {
  description: 'Find helpful links and downloadable content for caregivers of patients treated with SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Caregiver Downloads and Support'
}

const resources = [
  {
    thumb: 'newcargiverkit_thumbnail.png',
    title: 'Caregiver Kit',
    copy: 'Get a free, all-in-one SUPPRELIN<sup>&reg;</sup> LA Caregiver Kit sent directly to you by mail or email',
    href: '/patients/caregiver-signup',
    pdf: false,
    external: false,
    alt: 'SUPPRELIN LA Caregiver Information'
  },
  {
    thumb: 'SP-05378_caregiverbro_thumbnail.png',
    title: 'Caregiver Brochure',
    copy: 'Important information about CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/bb39c5e5-b03c-40fb-b52b-3110919e08ff/bb39c5e5-b03c-40fb-b52b-3110919e08ff_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Caregiver Brochure'
  },
  {
    thumb: 'SP-05379_gradybear_thumbnail.png',
    title: 'Grady Bear',
    copy: 'Storybook that makes CPP easy for kids to understand',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/3daa955d-9baa-4339-815c-d85703531154/3daa955d-9baa-4339-815c-d85703531154_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Grady Bear Book'
  },
  {
    thumb: 'SP-05697_appreminder_thumbnail.png',
    title: 'Appointment Reminder Card',
    copy: `Helps you keep your child's scheduled appointments`,
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/0bd6b1af-8b41-4b99-9f9b-2e25357a8d35/0bd6b1af-8b41-4b99-9f9b-2e25357a8d35_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Appointment Reminder Card'
  },
  {
    thumb: 'SP-05379_5reasons_thumbnail.png',
    title: '5 Good Reasons Tool',
    copy: `Helps you discuss SUPPRELIN<sup>&reg;</sup> LA with your child's doctor`,
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/32700162-42d5-4a99-b4d5-1eadd1f385cf/32700162-42d5-4a99-b4d5-1eadd1f385cf_source__v.pdf',
    pdf: true,
    external: false,
    alt: '5 Reasons to Ask About SUPPRELIN_LA'
  },
  {
    thumb: 'shares-program-leave-behind-thumbnail-img.png',
    title: 'SHARES Program Leave Behind',
    copy: 'Information on copay assistance',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/b2eb57f3-4692-47d9-a184-091143dadcfc/b2eb57f3-4692-47d9-a184-091143dadcfc_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Shares Program'
  },
  {
    thumb: 'SP-05374-caregiver-spanish.webp',
    title: 'Spanish: Caregiver Brochure',
    copy: 'Important information about CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/70327155-c50b-4495-8ab4-f7a62b0c9c71/70327155-c50b-4495-8ab4-f7a62b0c9c71_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Spanish Caregiver Brochure'
  },
  {
    thumb: 'SP-05383-5reasons-spanish.webp',
    title: 'Spanish: 5 Good Reasons Tool',
    copy: `Helps you discuss SUPPRELIN<sup>&reg;</sup> LA with your child's doctor`,
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/eb19b915-148d-48ee-afac-4d1b6c00f811/eb19b915-148d-48ee-afac-4d1b6c00f811_source__v.pdf',
    pdf: true,
    external: false,
    alt: '5 Reasons to Ask About SUPPRELIN LA Spanish'
  },
  {
    thumb: 'SP-05576_appointment_card_Spanish.png',
    title: 'Spanish: Appointment Reminder Card',
    copy: `Helps you keep your child's scheduled appointments`,
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/ee4ff62a-4622-4737-ab49-3523701e1e5a/ee4ff62a-4622-4737-ab49-3523701e1e5a_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Appointment Reminder Card'
  },
  {
    thumb: 'SP-05379_hgfound_thumbnail.png',
    title: 'HGFound.org',
    copy: 'The Human Growth Foundation leads the way in providing research, education, support, and advocacy in areas of growth or growth-hormone disorders',
    href: 'https://www.hgfound.org/',
    pdf: false,
    external: true,
    alt: 'hgfound.org'
  },
  {
    thumb: 'SP-05379_magicfound_thumbnail.png',
    title: 'MagicFoundation.org',
    copy: 'The MAGIC Foundation<sup>&reg;</sup> is the world’s largest organization for children and adults with growth-related disorders',
    href: 'https://www.magicfoundation.org/',
    pdf: false,
    external: true,
    alt: 'magicfoundation.org'
  },
  {
    thumb: 'post-procedure-care-tool-pdf-thumbnail.png',
    title: 'SUPPRELIN<sup>®</sup> LA Post-&#x2060;Procedure Care Tool',
    copy:
      'Helps you review what to look for after your child has received their <nobr>SUPPRELIN<sup>®</sup> LA</nobr> implant and keeps track of your next follow-up appointment',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/553bce0b-c8a5-4462-9d1a-b66995aa7a7b/553bce0b-c8a5-4462-9d1a-b66995aa7a7b_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Post-procedure care tool',
  },
  {
    thumb: 'pediatrician-discussion-guide-thumbnail.png',
    title: 'Pediatrician Discussion Guide',
    copy:
      'Use this tool to talk to your pediatrician before your child has been diagnosed with CPP',
    href: '/pdfs/SP-05778_General-Ped-Discussion-Guide.pdf',
    pdf: true,
    external: false,
    alt: 'Pediatrician Discussion Guide',
  },
  {
    thumb: 'patient-consent-form-thumbnail.png',
    title: 'Patient Consent Form',
    copy:
      'Complete this form to help start your SUPPRELIN<sup>®</sup> LA journey',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/c25260fc-8896-46d2-bee1-5aa4ff33fa26/c25260fc-8896-46d2-bee1-5aa4ff33fa26_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Patient Consent Form',
  },
]

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Caregiver information</h1>
        <h2>Here are some helpful links and downloadable content about central precocious puberty (CPP):</h2>
      </Col>
    </Row>
    <Collection collection={resources} pageName='patient downloads support' />
    <Row>
      <Col xs={12}>
        <p className="study-copy">All trademarks are the property of their respective owners.</p>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
