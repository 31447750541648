import React from 'react'
import { Row } from 'react-flexbox-grid'
import CollectionItem from '../collection-item/'

const OpenLink = (closeAction, openURL) => {
  closeAction()
  setTimeout(() => {
    window.open(openURL)
  }, 100)
}

const Collection = props => {

  const content = (props.centered) ? (
    <Row>
      {props.collection.map((item, index) => (
          <CollectionItem item={item} key={index} video={true} pageName={props.pageName} />
      ))}
    </Row>) : (
    <Row>
      {props.collection.map((item, index) => (
          <CollectionItem item={item} key={index} video={false} pageName={props.pageName} />
      ))}
    </Row>)
 
 return (
    <div>
       {content}
    </div>
  )
}

export default Collection
