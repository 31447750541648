import React from 'react'
import { Col } from 'react-flexbox-grid'
import './collection-item.scss'
import ActiveLink from '../active-link'
import myEmitter from '../../lib/emitter'
import { withPrefix } from 'gatsby'
import ExternalLink from '../../components/external-link'

let videoSubscription = null

class CollectionItem extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() { }

  onThumbClick = () => {
    myEmitter.emit('onPatientVideoClick', this.props.item);
  }

  handleClick = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    const { thumb, title, copy, href, pdf, external, alt, pageName, wistiaID } =
      this.props.item
    const { video } = this.props
    const image = require(`../../images/resources/${thumb}`).default
    const resourceURL = pdf ? withPrefix(href) : href

    const LinkComponent = external ? (
      <ExternalLink href={resourceURL} ExternalLink>
        <img src={image} alt={alt} title={alt} />
      </ExternalLink>
    ) : (
      <ActiveLink
        to={resourceURL}
        ExternalLink
        EventObject={{
          category: 'Downloads',
          action: 'Click',
          label: `${copy} - ${pageName}`,
        }}
      >
        <img src={image} alt={alt} title={alt} />
      </ActiveLink>
    )

    const content = !video ? (
      <Col xs={12} sm={6} md={4} lg={3} className={'collection-item-content'}>
        {LinkComponent}
        <h6 dangerouslySetInnerHTML={{ __html: title }}></h6>
        <p dangerouslySetInnerHTML={{ __html: copy }}></p>
      </Col>
    ) : (
      <Col xs={12} sm={6} md={4} lg={3} className={'collection-item-content'}>
        <a onClick={this.handleClick} href={`#wistia_${wistiaID}`}>
          <img src={image} alt={alt} title={alt} />
        </a>
        <p dangerouslySetInnerHTML={{ __html: copy }}></p>
      </Col>
    )
    return <React.Fragment>{content}</React.Fragment>
  }
}

export default CollectionItem
